<template>
  <button
    type="button"
    class="content-2col-card"
    v-ripple="isLinked"
    @click="$emit('click', $event)">
    <div v-if="src" class="content-2col-card-thumbnail">
      <figure class="content-2col-card-thumbnail__img">
        <img :src="src" :alt="title" />
      </figure>
    </div>

    <div class="content-2col-card-content">
      <div class="content-2col-card-content__head">
        <p v-if="title" class="content-2col-card-content__title">
          {{ title }}
        </p>

        <p v-if="subtitle" class="content-2col-card-content__subtitle">
          {{ subtitle }}
        </p>
      </div>

      <div v-if="tag" class="content-2col-card-content__tag">
        <Tag :tag="tag" />
      </div>
    </div>
  </button>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    href: {
      type: String,
      default: ''
    },

    src: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    tag: {
      type: String,
      default: ''
    }
  },

  setup: (props) => {
    const isLinked = computed(() => props.href && props.href.length > 0);

    return {
      isLinked
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.content-2col-card {
  $radiusSize: 12px;

  display: grid;
  row-gap: 8px;
  grid-template-rows: max-content;
  width: 100%;
  text-align: left;
  background: variables.$white01;
  border-bottom-right-radius: $radiusSize;
  border-bottom-left-radius: $radiusSize;
  box-shadow: variables.$elevationsLg;
}

.content-2col-card-thumbnail {
  &__img {
    @include mixin.imageAspectRatio(88, 88);
  }
}

.content-2col-card-content {
  display: grid;
  place-content: space-between start;
  grid-auto-columns: 1fr;
  grid-auto-rows: max-content;
  padding: 0 12px 12px;
  height: 100%;
  color: variables.$black01;

  &__head {
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    @include mixin.clamp(3);

    font-size: 15px;
    line-height: 1.25;
    font-weight: bold;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__subtitle {
    @include mixin.clamp(1);

    color: variables.$grey01;
    font-size: 10px;
    line-height: 1.33;
    font-weight: normal;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .c-icon {
      margin-right: 4px;
      width: 13px;
      height: 13px;
      vertical-align: top;

      g {
        fill: variables.$grey01;
      }
    }
  }

  &__tag {
    margin-bottom: -4px;
    align-self: end;
    min-height: 28px;
  }
}
</style>
